import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { SnackbarContent } from "@mui/material";

const CustomSnackbar = ({
  open,
  message,
  onClose,
  vertical = "bottom",
  horizontal = "center",
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={1500}
      onClose={onClose}
    >
      <SnackbarContent
        message={message}
        className="!leightBlack-bg flex justify-center !rounded-full capitalize !font-bold"
      />
    </Snackbar>
  );
};

export default CustomSnackbar;
