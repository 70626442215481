import Skeleton from "react-loading-skeleton";
import SkeletonWrapper from "../Skeleton/Skeleton";

export default function TimelineLoader() {
   return (
      <SkeletonWrapper>
         <div className={ ` absolute grid mx-8 sm:mx-[6rem] md:mx-auto  right-0  justify-end top-0  md:justify-center  md:h-screen h-fit w-full  z-20 ` } >
            <Skeleton height="100%" width={ 4 } />
         </div>
         <div className="md:grid grid-cols-2 md:gap-20 h-fit w-[90%] md:w-full">
            <div className=" col-span-1 gap-10  ">
               <div className="h-full">
                  <Skeleton height={ 350 } className="mt-10 col-span-1" />
                  <Skeleton height={ 500 } className="mt-10 col-span-1" />
               </div>
            </div>
            <div className="h-full md:mt-40 col-span-1">
               <Skeleton height={ 400 } className="mt-10 col-span-1" />
               <Skeleton height={ 500 } className="mt-10 col-span-1" />
            </div>

         </div>
      </SkeletonWrapper>
   )
}