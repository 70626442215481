/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Tooltip from "@mui/material/Tooltip";
import { CookieOutlined } from "@mui/icons-material";
import { toggleScrollLock } from "../../utils/helpers";
import {
  getSection,
  getLocations,
  setCookie,
  clearCookie,
} from "../../services/News.Service";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Header from "../../components/Header/Header";
import FooterWrapper from "../../components/Footer/Footer";
import Entertainment from "../../components/Sections/Entertainment/Entertainment";
import Tech from "../../components/Sections/Tech/Tech";
import Politics from "../../components/Sections/Politics/Politics";
import Society from "../../components/Sections/Society/Society";
import Business from "../../components/Sections/Business/Business";
import Sport from "../../components/Sections/Sport/Sport";
import Random from "../../components/Sections/Random/Random";
import Loaders from "../../components/Loaders/Loaders";
import Popup from "../../components/PopUp/Popup";

const topics = [
  "Politics",
  "Business",
  "Sport",
  "Entertainment",
  "Society",
  "Tech",
];

const renderSection = (data, topic) => {
  const Component = topic && topic in sections ? sections[topic] : null;
  return Component ? <Component section={data} key={topic} /> : null;
};

const sections = {
  Politics: Politics,
  Society: Society,
  Business: Business,
  Entertainment: Entertainment,
  Sport: Sport,
  Tech: Tech,
};

const Home = () => {
  const Footer = new FooterWrapper();
  const [closeDrawer, setCloseDrawer] = useState(false);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isCookies, setCookies] = useState(false);
  const pageSize = 15;

  useEffect(() => {
    const isEnabled = Cookies.get("locationEnabled");
    if (isEnabled === 'false') handleLocationModalOpen();
    else setCookies(true);

    toggleScrollLock(true);
  }, []);

  useEffect(() => {
    const fetchNewsData = async () => {
      const newsData = await Promise.all(
        topics.map((topic) => fetchData(topic))
      );
      setNews(newsData);
    };

    fetchNewsData();
  }, [pageSize]);

  useEffect(() => {
    if (news.length > 0) {
      setLoading(false);
    }
    window.scrollTo(0, 0);
  }, [news]);

  const locationQuery = useQuery({
    queryKey: ["location"],
    queryFn: () => getLocations().then((res) => [...res.data]),
  });

  const fetchData = async (topic) => {
    const params = {
      page_size: pageSize,
      topic,
    };

    const response = await getSection(params);
    const filteredData = response.data.filter(
      (sec) => sec.main_image.main_image !== null
    );
    return [...filteredData].sort((a, b) =>
      a?.article_category?.localeCompare(b?.article_category)
    );
  };

  const fetchNews = async () => {
    const newsData = await Promise.all(topics.map((topic) => fetchData(topic)));
    setNews(newsData);
  };

  const handleLocationModalOpen = () => {
    setShowLocationModal(true);
  };

  const handleLocationModalClose = () => {
    setShowLocationModal(false);
  };

  const handleLocationSelect = (location) => {
    // Toggle the selected state of the location
    setSelectedLocations((prevLocations) =>
      prevLocations.includes(location)
        ? prevLocations.filter((prevLocation) => prevLocation !== location)
        : [...prevLocations, location]
    );
  };

  const createFlagCookie = (flag) => {
    Cookies.set("locationEnabled", flag, { expires: 365 });
  };

  const handleLocationModalSave = () => {
    const locations = selectedLocations.join("&");
    setCookie({
      cookie_name: "locations",
      cookie_value: locations,
    })
      .then(() => createFlagCookie(true))
      .then(() => {
        setCookies(true);
        setLoading(true);
        fetchNews();
      })
      .finally(() => {
        setShowLocationModal(false);
      });
  };

  const handleClearCookies = () => {
    clearCookie({
      cookie_name: "locations",
      cookie_value: "All",
    })
      .then(() => createFlagCookie(false))
      .then(() => {
        setSelectedLocations([]);
        setCookies(false);
        setLoading(true);
        fetchNews();
      });
  };

  return (
    <>
      <Header closeDrawer={closeDrawer} setCloseDrawer={setCloseDrawer} />

      {/* DIVERDER */}
      <div className="pt-[100px] md:pt-[8rem]" />

      {loading && (
        <div className="mx-auto w-[77%]">
          <Loaders type="home" />
        </div>
      )}

      {/* SECTIONS */}
      {!loading && (
        <>
          {news.map((section, index) => (
            <div
              key={index}
              className="lg:flex justify-center items-center mx-4 sm:mx-8 md:mx-14 lg:mx-0"
            >
              <div className="lg:w-[77%]">
                {section.length > 0 && renderSection(section, topics[index])}
                <div className="w-full mx-auto  border-t my-16 border-neutral-700"></div>
              </div>
            </div>
          ))}

          {/* RANDOM SECTIONS */}
          <Random />
        </>
      )}

      {/* FOOTER */}
      <div className="w-full lg:w-[77%] mx-auto px-5 md:px-14 lg:px-0 mt-20">
        {Footer.links(useNavigate())}
      </div>

      {isCookies ? (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "21px",
            zIndex: 1000,
          }}
        >
          <Tooltip title="Clear Cookies" placement="top" arrow>
            <CookieOutlined
              sx={{
                fontSize: 45,
                background: "black",
                filter: "brightness(0.2)",
                "&:hover": {
                  cursor: "pointer",
                  filter: "brightness(0.4)", // Adjust brightness for hover effect
                },
              }}
              onClick={handleClearCookies}
            />
          </Tooltip>
        </div>
      ) : null}

      {/* Location Modal */}
      <Popup
        showLocationModal={showLocationModal}
        handleLocationModalClose={handleLocationModalClose}
        selectedLocations={selectedLocations}
        locationQuery={locationQuery}
        handleLocationModalSave={handleLocationModalSave}
        handleLocationSelect={handleLocationSelect}
      />
    </>
  );
};

export default Home;
