import React from "react";
import { data } from "../../utils/constant";
import "./Hero.css";

const Hero = () => {
  return (
    <section className="background-radial-gradient my-10 md:my-24 overflow-hidden">
      <div className="px-6 py-12 text-center md:px-12 lg:py-24 lg:text-left">
        <div className="w-100 mx-auto text-neutral-800 sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="grid items-center gap-12 lg:grid-cols-1">
            <div className="my-20 lg:mt-0 z-10">
              <h1 className="mt-0 mb-10 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl text-[hsl(218,81%,95%)]">
                {data.heroTitle[0]} <br />
                <span className="text-red-500">{data.heroTitle[1]}</span>
              </h1>
            </div>
            <div className="relative mb-12 lg:mb-0">
              <div
                id="radius-shape-1"
                className="absolute rounded-full shadow-lg"
              ></div>
              <div id="radius-shape-2" className="absolute shadow-lg"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
