/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getSummaries } from "./services/News.Service";
import { preFetchContext as PrefetchContext } from "./hooks/usePrefetch";
import AllRoutes from "./AllRoutes";

function App() {
  let timerInterval;
  const [timer, setTimer] = useState(0);

  const summariesQuery = useQuery({
    queryFn: () =>
      getSummaries({
        page: 3,
        page_size: 100,
        topic: ["Tech,Business,Politics,Society,Sport,Entertainment"].join(","),
      }).then((res) =>
        res.data
          ?.filter((sec) => sec.main_image?.main_image !== null)
          ?.sort((a, b) => a.article_category.localeCompare(b.article_category))
      ),
    queryKey: ["summaries", timer],
  });

  useEffect(() => {
    setInterval(() => setTimer((prev) => prev + 1), 640000);
    return () => clearInterval(timerInterval);
  }, []);
  return (
    <PrefetchContext.Provider value={ { summaries: summariesQuery.data } }>
      <AllRoutes />
    </PrefetchContext.Provider>
  );
}

export default App;
