import React from "react";
import { data } from "../../utils/constant";

class FooterWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.simple = this.simple.bind(this);
  }

  simple() {
    return (
      <footer className="w-full flex items-center justify-center">
        <div className="w-11/12 text-white flex flex-col">
          {/* GET IN TOUCH */}
          <div className="flex flex-col">
            <div className="flex mt-24 flex-row justify-between">
              <div className="w-full text-5xl md:text-7xl font-bold">
                <h1 className="w-30 lg:w-2/3">{data.about.title}</h1>
              </div>
            </div>
            <div className="flex mt-6 flex-col ">
              <p className="w-full  text-gray-100">{data.footerPara}</p>
            </div>

            {/* social links */}
            <div className="flex flex-wrap my-10 justify-between gap-5">
              {data.about.social.slice(0, 2).map((item, index) => {
                return (
                  <div
                    key={index}
                    className="relative border border-secondary rounded-lg p-6 lg:p-8 overflow-hidden w-full md:w-card"
                  >
                    <span className="m-auto">
                      <item.icon
                        width="45"
                        height="45"
                        fill="#fff"
                        className="p-3 bg-secondary mb-5 hover:shadow-black rounded-md "
                      />
                    </span>
                    <p className="mb-4">{item.para}</p>
                    <p>
                      <a
                        target="_blank"
                        className="text-blue-600 flex gap-x-2 items-center"
                        href={item.link}
                        title={item.link}
                        rel="noreferrer"
                      >
                        {item.linkText}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="20"
                          height="20"
                          className="fill-blue-500"
                          viewBox="0 0 64 64"
                        >
                          <path d="M 40 10 C 38.896 10 38 10.896 38 12 C 38 13.104 38.896 14 40 14 L 47.171875 14 L 30.585938 30.585938 C 29.804938 31.366938 29.804938 32.633063 30.585938 33.414062 C 30.976938 33.805063 31.488 34 32 34 C 32.512 34 33.023063 33.805062 33.414062 33.414062 L 50 16.828125 L 50 24 C 50 25.104 50.896 26 52 26 C 53.104 26 54 25.104 54 24 L 54 12 C 54 10.896 53.104 10 52 10 L 40 10 z M 18 12 C 14.691 12 12 14.691 12 18 L 12 46 C 12 49.309 14.691 52 18 52 L 46 52 C 49.309 52 52 49.309 52 46 L 52 34 C 52 32.896 51.104 32 50 32 C 48.896 32 48 32.896 48 34 L 48 46 C 48 47.103 47.103 48 46 48 L 18 48 C 16.897 48 16 47.103 16 46 L 16 18 C 16 16.897 16.897 16 18 16 L 30 16 C 31.104 16 32 15.104 32 14 C 32 12.896 31.104 12 30 12 L 18 12 z"></path>
                        </svg>
                      </a>
                    </p>
                  </div>
                );
              })}
            </div>
            <hr className="border-white mt-14" />
            <p className="w-full text-center my-6">
              Copyright © 2023 <b>{data.pageTitle}</b>
            </p>
          </div>
        </div>
      </footer>
    );
  }

  links = (navigate) => {
    return (
      <footer className="flex flex-col bg-highLight pt-32 px-5 md:px-8 relative overflow-hidden rounded-xl">
        <div className="h-32 w-32 md:h-56 md:w-56 bg-primary rounded-full absolute -right-10  md:-right-16 -top-10 md:-top-16" />
        <div className="h-24 w-24 md:h-36 md:w-36 bg-highLight rounded-full absolute  -right-8 md:-right-10 -top-8 md:-top-10 z-10" />
        <h5 className="text-2xl md:text-3xl font-semibold">
          The Value That holds us <br /> true and to account.
        </h5>

        <div className="flex flex-wrap justify-between gap-5 py-14">
          {data.footerContent.cards.map((item, index) => {
            return (
              <div className="w-full md:w-1/4" key={index}>
                <div className="flex items-center gap-6">
                  <item.icon
                    width="45"
                    height="45"
                    fill="#fff"
                    className="p-3 bg-black rounded-md"
                  />
                  <span className="font-bold">{item.title}</span>
                </div>
                <p className="py-4">{item.para}</p>
              </div>
            );
          })}
        </div>

        <div className="bg-black rounded-lg flex flex-wrap px-5 md:px-8 py-20 relative overflow-hidden">
          <div className="h-28 w-28 md:h-40 md:w-40 bg-primary rounded-full absolute -left-10 md:-left-14 -bottom-10 md:-bottom-14 z-10 flex items-center" />
          <h6 className="flex-1 text-xl md:text-3xl relative z-20 font-bold">
            Ready for a simple future? <br /> Let's get started
          </h6>
        </div>

        <div className="my-10 flex flex-wrap gap-10 md:gap-28 mt-8 md:mt-12">
          <span className="font-bold text-xl w-full md:w-max">
            Generative News <br /> © 2023
          </span>

          <div className="w-full md:w-max">
            <span className="font-semibold text-xl mb-4 inline-block">
              Company
            </span>
            <ul>
              {Object.entries(data.pages).map(([link, item], index) => {
                return (
                  <React.Fragment key={index}>
                    {link !== "/article" && (
                      <li className="py-1 text-gray-200">
                        <span
                          className="cursor-pointer hover:text-primary"
                          onClick={() => navigate(link)}
                        >
                          {item.title}
                        </span>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>

          <div className="w-full md:w-max">
            <span className="font-semibold text-xl mb-4 inline-block">
              Further Information
            </span>
            <ul>
              <li className="py-1 text-gray-200 cursor-pointer hover:text-primary">
                Terms & Condition
              </li>
              <li className="py-1 text-gray-200 cursor-pointer hover:text-primary">
                Privacy Policy
              </li>
            </ul>
          </div>

          <div className="w-full md:w-max">
            <span className="font-semibold text-xl mb-4 inline-block">
              Social
            </span>
            <div className="flex flex-wrap gap-4">
              {data.about.social.map((item, index) => {
                return (
                  <a
                    className="flex items-center gap-6"
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    href={item.title}
                  >
                    <item.icon
                      width="40"
                      height="40"
                      fill="#fff"
                      className="p-3 bg-black rounded-full"
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>

        <hr className="border-white mt-5 md:mt-14" />
        <p className="w-full text-center my-6">
          <b>{data.pageTitle}</b>
        </p>
      </footer>
    );
  };

  render() {
    return <></>;
  }
}

export default FooterWrapper;
