/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { removeDuplicates } from "../utils/helpers";
import { getSummaries, searchArticles } from "../services/News.Service";

const useFetchSummary = (page, filters, query) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [summaries, setSummeries] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const handleSummaries = (data) => setSummeries(data);

  useEffect(() => {
    setLoading(true);
    setError(false);

    const params = {
      page: page,
      ...filters,
    };

    getSummaries(params)
      .then((res) => {
        if (res.data.length === 0) setHasMore(false);
        else setHasMore(true);
        setSummeries((prev) => {
          const sums = removeDuplicates([...prev, ...res.data], "uuid");
          return sums;
        });
      })
      .catch((error) => {
        setHasMore(false);
        setError(error);
      })
      .finally(() => setLoading(false));
  }, [page, filters]);

  useEffect(() => {
    if (query) {
      setSummeries([]);
      setLoading(true);
      setHasMore(false);

      const params = {
        query: query,
      };

      searchArticles(params)
        .then((res) => {
          setSummeries(() => {
            const sums = removeDuplicates([...res.data], "uuid");
            return sums;
          });
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => setLoading(false));
    }
  }, [query]);

  return { loading, summaries, error, hasMore, handleSummaries };
};

export default useFetchSummary;
