import React from "react";
import Skeleton from "react-loading-skeleton";
import SkeletonWrapper from "../Skeleton/Skeleton";

export default function HomeLoader({ height = 200 }) {
  return (
    <SkeletonWrapper>
      <div className="grid grid-cols-12 mb-5 gap-3  ">
        <div className="col-span-1">
          <Skeleton width="100%" height={12} />
        </div>
        <div className="col-span-11">
          <Skeleton height={12} width="100%" />
        </div>
      </div>
      <div className=" md:grid  md:grid-cols-7  gap-5 relative w-full">
        <div className=" md:col-span-5 ">
          <div className=" sm:grid  sm:grid-cols-2 gap-10 relative w-full  ">
            {[...Array(2).keys()].map((item, index) => {
              return (
                <div key={index}>
                  <span className="cols-span-1">
                    <Skeleton height={height} />
                    <div className="">
                      <h2 className="mb-4">
                        <Skeleton count={2} height={12} />
                      </h2>
                      <p className="leading-4">
                        <Skeleton height={8} width="95%" />
                        <Skeleton height={8} width="75%" />
                      </p>
                    </div>
                  </span>
                </div>
              );
            })}
            {[...Array(4).keys()].map((item, index) => {
              return (
                <div key={index} className=" col-span-1  pb-8 sm:pb-0">
                  <div className=" grid grid-cols-3 ">
                    <div className=" col-span-1   ">
                      <Skeleton height={height / 2} />
                    </div>
                    <div className="col-span-2 pl-4  ">
                      <h2 className="mb-4">
                        <Skeleton count={2} height={12} />
                      </h2>
                    </div>
                  </div>
                  <div className=" mt-3">
                    <p className="leading-4">
                      <Skeleton height={8} width="95%" />
                      <Skeleton height={8} width="75%" />
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className=" col-span-2">
          <Skeleton height="100%" />
        </div>
      </div>

      <div className="grid grid-cols-12 mb-5 gap-5  mt-20">
        <div className="col-span-2">
          <Skeleton width="100%" height={10} />
        </div>
        <div className="col-span-10">
          <Skeleton height={10} width="100%" />
        </div>
      </div>
      <div className=" md:grid  md:grid-cols-7  gap-5 relative w-full mb-96">
        <div className=" md:col-span-5 ">
          <div className=" sm:grid  sm:grid-cols-2 gap-10 relative w-full  ">
            {[...Array(4).keys()].map((item, index) => {
              return (
                <div key={index}>
                  <span className="cols-span-1">
                    <Skeleton height={height} />
                    <div className="">
                      <h2 className="mb-4">
                        <Skeleton count={2} height={12} />
                      </h2>
                      <p className="leading-4">
                        <Skeleton height={8} width="95%" />
                        <Skeleton height={8} width="75%" />
                      </p>
                    </div>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className=" col-span-2 ">
          <Skeleton height="100%" />
        </div>
      </div>
    </SkeletonWrapper>
  );
}
