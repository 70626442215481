import axios from "./Axios.Instance";

export const getSummaries = (params) => {
  const topic = params.topic
  const location = params.location
  const page = params.page

  let requestParams = "/summaries?page=" + page
  if (topic)
    requestParams += "&topic=" + topic
  if (location)
    requestParams += "&location=" + location


  return axios.get(requestParams);
};

export const getTopics = (params) => {
  return axios.get("/topics", {
    params: params,
  });
};

export const getLocations = (params) => {
  return axios.get("/locations", {
    params: params,
  });
};

export const getArticleById = (params) => {
  return axios.get("/summaries?uuid=" + params);
};

export const searchArticles = (params) => {
  return axios.get("/search", {
    params,
  });
};

export const getTicker = (params) => {
  return axios.get("/news_ticker", {
    params,
  });
};

export const getSection = (params) => {
  return axios.get("/sections", {
    params,
  });
};

export const getCrypto = (params) => {
  return axios.get("/crypto_values");
};

export const getTimeLine = () => {
  return axios.get("/timeline");
};

export const setCookie = (params) => {
  return axios.get("/set-cookie", {
    params,
  });
}

export const clearCookie = (params) => {
  return axios.get('/clear-cookie', {
    params
  })
}
