import React from "react";
import TickerForSection from "../../NewsTicker/TickerForSection"
import Image from "../../Image/Image";
import { createArticleUrl, createFeedUrl } from "../../../utils/helpers";
import { getCrypto } from "../../../services/News.Service";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export default function Business({ section, pageSize }) {
   const nav = useNavigate();


   const cryptoQuery = useQuery({
      queryFn: async () => {
         const data = await getCrypto();
         return data.data;
      },
      queryKey: ["crypto"],
   });

   return (
      <>
         { section[0]?.title && (
            <div className="">
               {/* Topic and Ticker for topic */ }
               { section[0]?.topic && (
                  <div className="flex gap-1 mb-6 md:mb-8 md:gap-2 items-center" >
                     <button
                        className="text-white border-b-2  border-red-600 text-xs md:text-sm font-bold hover:text-neutral-400"
                        onClick={ () => nav(createFeedUrl(section[0].topic)) }
                     >
                        { section[0].topic }
                     </button>
                     <TickerForSection filterTopic={ section[0].topic } />
                  </div>
               ) }

               {/* Section Data */ }
               <div className="">

                  <div className=" md:grid  md:grid-cols-7  gap-5 relative w-full">

                     <div className="  md:col-span-5 ">
                        <div className=" sm:grid  sm:grid-cols-2 gap-10 relative w-full  ">
                           { section.slice(0, 4).map((section, index) => (
                              <a
                                 key={ index }
                                 className="hover:text-neutral-400 "
                                 title={ section.title }
                                 href={ createArticleUrl(section.uuid) }
                              >
                                 <div className="mb-10 md:mb-0">
                                    <div className="imageWrapper h-[200px]  ">
                                       <Image image={ section.main_image } title={ section.title } position="relative" />
                                    </div>
                                    <div className=" pt-3">
                                       <h1 className="text-base font-bold pb-2">{ section.title }</h1>
                                       <p className="text-xsl text-gray-400 pr-12 ">{ section.response.slice(0, 150) + "..." }</p>
                                    </div>
                                 </div>
                              </a>
                           )) }
                           { cryptoQuery.isSuccess &&
                              <div className=" mb-8 md:m-0 justify-start font-mono ">
                                 <p className="text-red-500 uppercase font-semibold pb-1 text-xsl">live:</p>
                                 <p className=" font-bold text-blue-300  text-sm">
                                    Bitcoin: { cryptoQuery.data["bitcoin"].price.toString().slice(0, 8) } $
                                 </p>
                                 <p className=" font-bold text-blue-300 text-sm">
                                    Ethereum: { cryptoQuery.data["ethereum"].price.toString().slice(0, 7) } $
                                 </p>
                              </div>
                           }
                        </div>
                     </div>

                     <div className=" col-span-2 h-full md:h-[44rem] overflow-auto custom-scrollbar  md:!border-l border-neutral-700  md:pt-0 sm:m-2 md:m-0 md:pl-4  ">
                        { section.slice(4, 10).map((section, index) => (
                           <a
                              key={ index }
                              className=" hover:text-neutral-400 mb-10 b"
                              title={ section.title }
                              href={ createArticleUrl(section.uuid) }
                           >
                              <div className="  mb-4  md:px-0  border-b pb-5 border-neutral-800">
                                 <div className=" ">
                                    <h1 className="font-bold text-sm ">{ section.title }</h1>
                                    <p className="text-xsl  text-gray-400 pt-2">{ section.response.slice(0, 150) + "..." }</p>
                                 </div>
                              </div>
                           </a>
                        )) }
                     </div>
                  </div>
               </div>
            </div>
         ) }
      </>
   );
}
