import React from "react";
import NewsCardInner from "../NewsCardInner/NewsCardInner";

const NewsCard = (props) => {
  return (
    <div className={props.classes}>
      <NewsCardInner item={props.item} />
    </div>
  );
};

export default NewsCard;
