/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "@mui/material";
import { getConciseDate, toggleScrollLock } from "../../utils/helpers";
import { ReactComponent as LocationIcon } from "../../assests/icons/location-dot.svg";
import { getArticleById } from "../../services/News.Service";
import { FeedCardLoader } from "../../components/Loaders/Feed.Loader";
import { preFetchContext as PrefetchContext } from "../../hooks/usePrefetch";
import Header from "../../components/Header/Header";
import NewsCard from "../../components/NewsCard/NewsCard";
import Image from "../../components/Image/Image";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

export default function SmartArticle({ uuid = null }) {
  const { summaries } = useContext(PrefetchContext);
  const isMobile = useMediaQuery("(max-width:768px)");
  const params = useParams();
  const [data, setData] = React.useState({});
  const [closeDrawer, setCloseDrawer] = React.useState(false);
  let articleUuid = uuid ? uuid : params.uuid

  const nav = useNavigate();

  React.useEffect(() => {
    if (!uuid) {
      window.scrollTo(0, 0);
      toggleScrollLock(true);
    }
  }, [params]);

  const articleQuery = useQuery(['article', articleUuid], () => getArticleById(articleUuid), {
    enabled: !!articleUuid,
  });

  React.useEffect(() => {
    if (articleQuery.isSuccess) {
      setData(articleQuery.data.data);
    }
  }, [articleQuery]);

  return (
    <main className={ uuid && "custom-scrollbar overflow-auto" }>
      {/* header */ }
      { !uuid && <Header closeDrawer={ closeDrawer } setCloseDrawer={ setCloseDrawer } /> }

      <article className={ clsx("",
        uuid ? " " : "w-full md:px-10 md:w-9/12 lg:w-2/3 mx-auto") }>
        {/* loading */ }
        { articleQuery.isLoading && (
          <div className="pt-20">
            <FeedCardLoader height={ isMobile ? 300 : 600 } />
          </div>
        ) }

        { articleQuery.isSuccess && (
          <>
            {/* Article */ }
            <div className={ `${uuid ? "pt-[4rem]" : "pt-[7rem]"}` }>
              { !data?.main_image?.main_image && (
                <div className="border border-white mx-4 md:mx-0"></div>
              ) }

              {/* Image */ }
              { data?.main_image?.main_image && (
                <div className="imageWrapper">
                  <a
                    href={ data.main_image?.link }
                    target="_blank"
                    title={ data.main_image?.main_image }
                    rel="noreferrer"
                  >
                    <Image image={ data.main_image } title={ data?.title } />
                  </a>
                </div>
              ) }

              {/* Other Content */ }
              <div className="mx-4 md:mx-0">
                <div className="pb-10">
                  {/* Topic & Date */ }
                  <div className="flex justify-between items-center py-5">
                    <span className="text-sm text-primary font-bold">
                      { data?.topic }
                    </span>
                    <span className="text-sm text-primary font-bold">
                      { getConciseDate(data?.timestamp) }
                    </span>
                  </div>

                  <h1 className="font-extrabold text-2xl md:text-4xl lg:text-4xl mt-4 mb-6">
                    { data?.title }
                  </h1>

                  {/* location tags */ }
                  { data?.location?.toLowerCase() !== "all" ? (
                    <div className="inline-flex rounded-full my-1 mr-2 px-2 gap-1 py-1 tracking-wide bg-secondary">
                      <LocationIcon
                        width="12"
                        height="12"
                        fill="#ffffff"
                        className="my-auto mr-1"
                      />
                      <span className=" text-xs text-white ">
                        { data?.location }
                      </span>
                    </div>
                  ) : (
                    data?.location_counts &&
                    Object.entries(data?.location_counts)?.map(
                      (i, index) => {
                        return (
                          <div
                            className="inline-flex items-center rounded-full my-1 mr-2 px-2 gap-1 py-1 tracking-wide bg-secondary"
                            key={ index }
                          >
                            <LocationIcon
                              width="12"
                              height="12"
                              fill="#ffffff"
                              className="my-auto mr-1"
                            />
                            <span className="text-xs text-white mr-2">
                              { i[0] }
                            </span>
                            { i[1] && i[1] > 1 && (
                              <div className="w-5 h-5 bg-[#1b1b1b] rounded-full flex items-center justify-center text-xs">
                                { i[1] }
                              </div>
                            ) }
                          </div>
                        );
                      }
                    )

                  ) }

                  <p className="text-gray-300 mt-5 text-lgl">{ data?.response }</p>
                </div>
                {/* sources */ }
                { data?.links?.length !== 0 && (
                  <div className="">
                    <span className="inline-block text-lg my-4 font-bold text-white">
                      Sources
                    </span>
                    <div className="flex flex-wrap gap-y-2">
                      { data?.links?.map((i, index) => {
                        return (
                          <a
                            key={ index }
                            href={ i }
                            title={ i }
                            target="_black"
                            className="flex text-whiteSmoke font-bold capitalize hover:text-white mr-4"
                          >
                            source { index + 1 }
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="20"
                              height="20"
                              fill="whiteSmoke"
                              className="ml-1"
                              viewBox="0 0 64 64"
                            >
                              <path d="M 40 10 C 38.896 10 38 10.896 38 12 C 38 13.104 38.896 14 40 14 L 47.171875 14 L 30.585938 30.585938 C 29.804938 31.366938 29.804938 32.633063 30.585938 33.414062 C 30.976938 33.805063 31.488 34 32 34 C 32.512 34 33.023063 33.805062 33.414062 33.414062 L 50 16.828125 L 50 24 C 50 25.104 50.896 26 52 26 C 53.104 26 54 25.104 54 24 L 54 12 C 54 10.896 53.104 10 52 10 L 40 10 z M 18 12 C 14.691 12 12 14.691 12 18 L 12 46 C 12 49.309 14.691 52 18 52 L 46 52 C 49.309 52 52 49.309 52 46 L 52 34 C 52 32.896 51.104 32 50 32 C 48.896 32 48 32.896 48 34 L 48 46 C 48 47.103 47.103 48 46 48 L 18 48 C 16.897 48 16 47.103 16 46 L 16 18 C 16 16.897 16.897 16 18 16 L 30 16 C 31.104 16 32 15.104 32 14 C 32 12.896 31.104 12 30 12 L 18 12 z"></path>
                            </svg>
                          </a>
                        );
                      }) }
                    </div>
                  </div>
                ) }
              </div>
            </div>
            {/* Related Articles */ }
            { !uuid && <div className="py-16 md:py-20 px-4 pb-14 md:px-0">
              <h1 className="text-xl md:text-2xl font-bold">
                Related Articles
              </h1>

              <div className="mt-10 md:py-2">
                <ResponsiveMasonry
                  columnsCountBreakPoints={ { 350: 1, 750: 2, 900: 2 } }
                >
                  <Masonry gutter={ 20 }>
                    { summaries
                      ?.filter(
                        (i) =>
                          i?.topic === data?.topic && i?.uuid !== params.uuid
                      )
                      .slice(0, 10)
                      ?.map((item, index) => {
                        return (
                          <div className="" key={ index }>
                            <NewsCard item={ item } />
                          </div>
                        );
                      }) }
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div> }
          </>
        ) }
        {/* Error */ }
        { articleQuery.isError && (
          <div className="flex justify-center pt-[7rem] md:pt-[10rem]">
            <p>
              Somthing Went Wrong Would You Like to
            </p>
            <button className="text-blue-500 indent-2" onClick={ () => nav("/newsfeed") }>
              Explore More!
            </button>
          </div>
        ) }
      </article>
    </main>
  );
}