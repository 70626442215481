import { Route, Routes } from "react-router-dom";
import Landing from "../src/pages/Home/Home";
import NewsFeed from "../src/pages/Feed/Feed";
import Article from "./pages/Article/Article";
import AboutUs from "./pages/AboutUs/AboutUs";
import Timeline from "./pages/Timeline/Timeline";


const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={ <Landing /> } />
      <Route path="/newsfeed" element={ <NewsFeed /> } />
      <Route path="/article/:uuid" element={ <Article /> } />
      <Route path="/aboutus" element={ <AboutUs /> } />
      <Route path="/timeline" element={ <Timeline /> } />
      <Route path="/*" element={ <Landing /> } />
    </Routes>
  );
};

export default AllRoutes;
