import React, { useState, useEffect } from "react";
import { ReactComponent as UpIcon } from "../../assests/icons/circlechevronup.svg";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`fixed right-6 bottom-20 z-20 ${
        isVisible
          ? "opacity-40 hover:opacity-100"
          : "opacity-0 pointer-events-none"
      } transition-opacity duration-300 ease-in-out`}
    >
      <button
        onClick={scrollToTop}
        className="fill-neutral-700 md:fill-white   rounded-full "
      >
        <UpIcon height={40} width={40} />
      </button>
    </div>
  );
}

export default ScrollToTopButton;
