/* eslint-disable react-hooks/exhaustive-deps */
import "./index.css";
import React from "react";
import App from "./App";
import ScrollToTop from "../src/components/ScrollToTop/ScrollToTop";

import { inject } from "@vercel/analytics";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const RootComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<RootComponent />);
inject();
