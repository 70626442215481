export const API_BASE_URL = "https://generative-news.lm.r.appspot.com/api";
// export const API_BASE_URL = "https://x55ws2dz-8080.euw.devtunnels.ms/api"
// export const API_BASE_URL = "http://192.168.1.11:8080/api"
export const getAxiosConfig = () => {
  const authToken = localStorage.getItem("userToken");
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };
};
