import Image from "../../Image/Image";
import "react-responsive-carousel/lib/styles/carousel.css";
import { createArticleUrl, createFeedUrl } from "../../../utils/helpers";
import TickerForSection from "../../NewsTicker/TickerForSection";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Sport({ section, pageSize }) {

   const isMobile = useMediaQuery("(max-width:768px)");

   const nav = useNavigate()
   return (
      <>
         { section &&
            (<div className="  ">
               {/* Topic and Ticker for topic */ }
               { section[0]?.topic && (
                  <div className="flex gap-1 pb-8  md:gap-2 items-center" >
                     <button
                        className="text-white border-b-2  border-red-600 text-xs md:text-sm font-bold hover:text-neutral-400"
                        onClick={ () => nav(createFeedUrl(section[0].topic)) }
                     >
                        { section[0].topic }
                     </button>
                     <TickerForSection filterTopic={ section[0].topic } />
                  </div>
               ) }
               <div className=" md:grid  md:grid-cols-7  gap-5 relative w-full">
                  <div className="  md:col-span-5 ">
                     <a
                        className="hover:text-neutral-400"
                        title={ section[0].title }
                        href={ createArticleUrl(section[0].uuid) }
                     >
                        {/*main image & one-liner &title */ }
                        <div>
                           {/*main Image */ }
                           <div className="flex md:h-[30rem]  md:relative ">
                              <Image image={ section[0].main_image } title={ section[0].title } objectFit={ "contain" } position={ isMobile ? "relative" : "absolute" } />
                           </div>
                           {/*main Title */ }
                           <div className="pt-3">
                              <span className="text-base md:text-basel  font-bold">{ section[0].title }</span>
                           </div>
                           {/*main one-liner */ }
                           <div className="flex pt-2">
                              <span className="text-xs md:text-xsl text-gray-400">{ section[0].response.slice(0, 150) + "..." }</span>
                           </div>
                        </div>
                     </a>
                  </div>

                  <div className=" col-span-2 h-full md:h-[30rem] md:overflow-auto custom-scrollbar md:border-l border-neutral-700 my-8  sm:mt-10 sm:mx-2 md:m-0 md:pl-4  ">
                     { section.slice(1, 8).map((section, index) => (
                        <a
                           key={ index }
                           className=" hover:text-neutral-400 mb-10 b"
                           title={ section.title }
                           href={ createArticleUrl(section.uuid) }
                        >
                           <div className="  mb-3  md:px-0 bor las border-b pb-4 border-neutral-800">
                              <div className=" ">
                                 <h1 className="font-bold text-xsl ">{ section.title }</h1>
                                 <p className="text-xs  text-gray-400 pt-2">{ section.response.slice(0, 150) + "..." }</p>
                              </div>
                           </div>
                        </a>
                     )) }
                  </div>
               </div>
            </div>
            )
         }



      </>
   );
}

