import * as React from "react";
import { toggleScrollLock } from "../../utils/helpers";
import SmoothScroll from "../../components/SmoothScroll/SmoothScroll";
import Header from "../../components/Header/Header";
import Work from "../../components/Work/Work";
import Inner from "../../components/Inner/Inner";
import Hero from "../../components/Hero/Hero";
import FooterWrapper from "../../components/Footer/Footer";

const AboutUs = () => {
  const [closeDrawer, setCloseDrawer] = React.useState(false);
  const Footer = new FooterWrapper();

  React.useEffect(() => {
    toggleScrollLock(true);
  }, []);

  return (
    <SmoothScroll>
      <main>
        <Header closeDrawer={ closeDrawer } setCloseDrawer={ setCloseDrawer } />
        <div className="h-24"></div>
        <Hero />
        <Inner id="a" />
        <Work />
        { Footer.simple() }
      </main>
    </SmoothScroll>
  );
};

export default AboutUs;
