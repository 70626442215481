import React from "react";
import Skeleton from "react-loading-skeleton";
import SkeletonWrapper from "../Skeleton/Skeleton";

const FeedLoader = () => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-20">
      { [...Array(2).keys()].map((item, index) => {
        return (
          <React.Fragment key={ index }>
            <div className="col-span-0 lg:col-span-1">
              <FeedCardLoader />
            </div>
            <div className="col-span-1 lg:col-span-1">
              <FeedCardLoader />
            </div>
            <div className="col-span-1 lg:col-span-1">
              <FeedCardLoader />
            </div>
          </React.Fragment>
        );
      }) }
    </div>
  );
};

export default FeedLoader;

export const FeedCardLoader = ({ height = 250 }) => {
  return (
    <SkeletonWrapper>
      <Skeleton height={ height } />

      <div className="flex justify-between items-center py-5">
        <span>
          <Skeleton width={ 70 } height={ 10 } />
        </span>
        <span>
          <Skeleton width={ 60 } height={ 10 } />
        </span>
      </div>

      <div className="">
        <h2 className="mb-4">
          <Skeleton count={ 2 } height={ 12 } />
        </h2>
        <p className="leading-4">
          <Skeleton height={ 8 } width="95%" />
          <Skeleton height={ 8 } width="75%" />
          <Skeleton height={ 8 } width="60%" />
        </p>
      </div>
    </SkeletonWrapper>
  );
};
