
import Image from "../../Image/Image";
import TickerForSection from "../../NewsTicker/TickerForSection";
import { createArticleUrl, createFeedUrl } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";

export default function Tech({ section, pageSize }) {
   const nav = useNavigate();

   return (
      <>
         { section &&
            (<div className=" ">

               {/* Topic and Ticker for topic */ }
               { section[0]?.topic && (
                  <div className="flex gap-1 pb-8  md:gap-2 items-center" >
                     <button
                        className="text-white border-b-2  border-red-600 text-xs md:text-sm font-bold hover:text-neutral-400"
                        onClick={ () => nav(createFeedUrl(section[0].topic)) }
                     >
                        { section[0].topic }
                     </button>
                     <TickerForSection filterTopic={ section[0].topic } />
                  </div>
               ) }
               <div className=" md:grid  md:grid-cols-5 relative w-full  ">
                  <div className="  md:col-span-3 >bg-red-500  ">

                     { section.slice(0, 4).map((section, index) => (
                        <a
                           key={ index }
                           className="hover:text-neutral-400"
                           title={ section.title }
                           href={ createArticleUrl(section.uuid) }
                        >
                           <div key={ index } className=" sm:grid  sm:grid-cols-2 mb-6 ">
                              <div className="flex md:col-span-1 h-[200px] >bg-slate-500">
                                 <Image image={ section.main_image } title={ section.title } />
                              </div>
                              <div className="sm:col-span-1  sm:pl-4 md:pl-5  ?bg-green-500">
                                 <h1 className="font-bold text-base pt-2 md:pt-0">{ section.title }</h1>
                                 <p className="text-xs pt-2 text-gray-400">{ section.response.slice(0, 150) + "..." }</p>
                              </div>
                           </div>
                        </a>
                     )) }
                  </div>
                  <div className=" col-span-2 >bg-yellow-500">
                     <div className=" ">
                        {/* Second grid card */ }
                        <div className="flex  md:block md:pl-8 lg:pl-20  pr-1 pt-5 md:pt-0 border-y-2 border-neutral-800  md:border-none h-full md:h-[52rem] overflow-auto custom-scrollbar    ">

                           { section.slice(4, section.length).map((sec, index) => (
                              <a
                                 key={ index }
                                 className="hover:text-neutral-400 "
                                 title={ sec.title }
                                 href={ createArticleUrl(sec.uuid) }
                              >
                                 <div className="md:mb-6 ml-2 mr-5 md:mx-0   ">
                                    <div className=" imageWrapper w-[16rem]  md:w-full   relative">
                                       <Image image={ sec.main_image } title={ sec.title } height={ "12rem" } />
                                    </div>
                                    <div className="flex pt-3">
                                       <span className="font-bold md:text-base ">{ sec.title }</span>
                                    </div>

                                 </div>

                              </a>
                           )) }
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            )
         }
      </>
   );
}

