/* eslint-disable jsx-a11y/anchor-is-valid */
import Image from "../../Image/Image";
import { createArticleUrl, createFeedUrl } from "../../../utils/helpers";
import TickerForSection from "../../NewsTicker/TickerForSection";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Entertainment({ section, pageSize }) {

   const isMobile = useMediaQuery("(max-width:768px)");
   const nav = useNavigate();

   return (
      <>
         { section &&
            (<div className=" ">

               {/* Topic and Ticker for topic */ }
               { section[0]?.topic && (
                  <div className="flex gap-1 pb-8  md:gap-2 items-center" >
                     <button
                        className="text-white border-b-2  border-red-600 text-xs md:text-sm font-bold hover:text-neutral-400"
                        onClick={ () => nav(createFeedUrl(section[0].topic)) }
                     >
                        { section[0].topic }
                     </button>
                     <TickerForSection filterTopic={ section[0].topic } />
                  </div>
               ) }
               <div className=" md:grid  md:grid-cols-3  gap-5 relative w-full">
                  <div className="  md:col-span-2 ">
                     <a
                        className="hover:text-neutral-400"
                        title={ section[0].title }
                        href={ createArticleUrl(section[0].uuid) }
                     >
                        {/*main image & one-liner &title */ }
                        <div>
                           {/*main Image */ }
                           <div className="flex md:h-[30rem]  md:relative ">
                              <Image image={ section[0].main_image } title={ section[0].title } objectFit={ "contain" } position={ isMobile ? "relative" : "absolute" } />
                           </div>
                           {/*main Title */ }
                           <div className="pt-3">
                              <span className="text-base md:text-lg  font-bold">{ section[0].title }</span>
                           </div>
                           {/*main one-liner */ }
                           <div className="flex pt-2">
                              <span className="text-xs md:text-xs text-gray-400">{ section[0].response.slice(0, 150) + "..." }</span>
                           </div>
                        </div>
                     </a>
                  </div>

                  <div className=" col-span-1 h-full md:h-[30rem] md:overflow-auto custom-scrollbarmy-10 md:m-0 p-2 md:pt-0  ">


                     <div className="flex mt-10 md:mt-0 overflow-x-visible custom-scrollbar border-y-2 border-neutral-800  md:border-none pt-5 md:pt-0 overflow-y-hidden md:block md:overflow-x-hidden h-full md:!overflow-y-auto ">

                        { section.map((sec, index) => (
                           index !== 0 && (
                              <a
                                 key={ index }
                                 className=" "
                                 title={ sec.title }
                                 href={ createArticleUrl(sec.uuid) }
                              >
                                 <div className="mb-4 mr-4 md:pl-4 hover:text-neutral-400 md:mr-1 h-full md:h-auto border-black md:border-none  ">
                                    <div className=" imageWrapper  w-[18rem] md:w-full   relative">
                                       <Image image={ sec.main_image } title={ sec.title } height={ "10rem" } />
                                    </div>
                                    <div className="flex pt-3">
                                       <span className="font-bold md:text-base px-1">{ sec.title }</span>
                                    </div>

                                 </div>
                              </a>
                           )
                        )) }
                     </div>
                  </div>
               </div>
            </div>
            )
         }



      </>
   );
}