import React from 'react'
import Loaders from '../Loaders/Loaders';
import Chip from './Chip';

const LocationChips = ({locationQuery,filters,handleFilterSelect}) => {
  return (
   <>
    <h3 className="text-lg md:text-xl lg:text-xl font-bold tracking-wide mt-7 mb-2">
            Locations
          </h3>
          <div className="flex flex-wrap px-2 py-4 h-48 overflow-y-auto border-2 custom-scrollbar border-secondary rounded-xl">
            {locationQuery.isLoading ? (
              <Loaders type="chip" />
            ) : (
              <>
                {locationQuery?.data?.map((i, index) => {
                  const isActive = filters?.location?.includes(i);
                  return (
                    <Chip
                      text={i}
                      key={index}
                      isActive={isActive}
                      onClick={() => handleFilterSelect(i, "l")}
                    />
                  );
                })}
              </>
            )}
          </div>
   </>
  )
}

export default LocationChips