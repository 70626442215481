import moment from "moment";

export function getConciseDate(dateString) {
  // "Mon, 25 Sep 2023 01:26:39 GMT"
  const date = moment(
    new Date(dateString),
    "ddd, DD MMM YYYY HH:mm:ss [GMT]",
    "en"
  );
  return date.format("MMM DD, YYYY");
}

export function getTimeFromStringDate(dateString) {
  // Create a Date object from the string
  const dateObject = new Date(dateString);

  // Get hours, minutes, and seconds
  const hours = dateObject.getUTCHours();
  const minutes = dateObject.getUTCMinutes();

  // Format the time
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return formattedTime;
}

export function removeDuplicates(arr, prop) {
  const seen = new Set();
  return arr.filter((item) => {
    const key = item[prop];
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
}

export function formatDate(inputDate) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateParts = inputDate.split("/");
  const month = months[parseInt(dateParts[0], 10) - 1];
  const day = dateParts[1];
  const year = dateParts[2];

  const formattedDate = new Date(`${month} ${day} ${year}`).toDateString();
  return formattedDate;
}

// export function groupDataByDate(originalData) {
//   const groupedData = {};

//   // Iterate through the original data
//   Object.keys(originalData).forEach((key) => {
//     originalData[key].forEach((item) => {
//       // Extract the date from the timestamp
//       const date = new Date(item.timestamp).toLocaleDateString();

//       // Initialize an array for the date if it doesn't exist
//       if (!groupedData[date]) {
//         groupedData[date] = [];
//       }

//       // Push the item to the corresponding date
//       groupedData[date].push(item);
//     });
//   });

//   return groupedData;
// }

export function groupDataByDate(originalData) {
  const groupedData = {};

  // Iterate through the original data
  Object.keys(originalData).forEach((key) => {
    groupedData[key] = {};

    originalData[key].forEach((item) => {
      // Extract the date from the timestamp
      const date = new Date(item.timestamp).toLocaleDateString();

      // Initialize an array for the date if it doesn't exist
      if (!groupedData[key][date]) {
        groupedData[key][date] = [];
      }

      // Push the item to the corresponding date
      groupedData[key][date].push(item);
    });
  });

  return groupedData;
}


export function toggleScrollLock(check) {
  const body = document.body;
  !check && body.classList.add("disable-scroll");
  check && body.classList.remove("disable-scroll");
}

export function createArticleUrl(uuid) {
  return `/article/${uuid}`;
}

export function createCopyUrl(uuid) {
  return `${window.location.origin}/article/${uuid}`;
}

export function createFeedUrl(params) {
  return `/newsfeed?topic=${params}`;
}
