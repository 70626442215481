import React from "react";
import clsx from "clsx";

function ExploreButton({
  end,
  onClick,
  classes = { wrapper: "", button: "" },
}) {
  return (
    <div className={ clsx("flex justify-center mt-10", classes?.wrapper) }>
      <p className="text-lightSecondary">{ end.endMessage }</p>
      <button
        className="font-semibold inline-block pl-2 hover:text-neutral-400"
        onClick={ onClick }
      >
        { end.endButton }
      </button>
    </div>
  );
}

export default ExploreButton;
