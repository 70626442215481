/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { toggleScrollLock } from "../../utils/helpers";
import { data } from "../../utils/constant";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import clsx from "clsx";
import NewsCard from "../../components/NewsCardRef/NewsCardRef";
import Loaders from "../../components/Loaders/Loaders";
import DrawerFilters from "../../components/Drawer/DrawerFilters";
import useFetchSummary from "../../hooks/useFetchSummary";
import NewsCardRef from "../../components/NewsCardRef/NewsCardRef";
import Header from "../../components/Header/Header";
import ExploreButton from "../../components/ExploreButton/ExploreButton";

export const initialFilters = () => {
  let location = []
  let topic = []

  const params = new URLSearchParams(window.location.search);
  const topics = params.get('topic')
  if (topics) {
    return {
      topic: [topics],
      location: location,
    }
  }

  if (localStorage.getItem('location')) {
    location = localStorage.getItem('location')
    location = typeof location == 'string' && (location.split(','));
  }
  if (localStorage.getItem('topic')) {
    topic = localStorage.getItem('topic')
    topic = typeof topic == 'string' && (topic.split(','));
  }

  return {
    topic: topic,
    location: location,
  }
};

const Feed = () => {
  const [closeDrawer, setCloseDrawer] = React.useState(true);
  const observer = React.useRef();
  const [page, setPage] = React.useState(1);
  const [end, setEnd] = React.useState("");
  const [filters, setFilters] = React.useState(initialFilters());
  const [query, setQuery] = React.useState("");
  const [selectedFilters, setSelectedFilter] = React.useState(initialFilters());
  const { loading, summaries, hasMore, handleSummaries } = useFetchSummary(
    page,
    filters,
    query
  );

  // Hooks
  React.useEffect(() => {
    toggleScrollLock(true);
  }, []);

  React.useEffect(() => {
    if (!hasMore) {
      setEnd(summaries.length === 0 ? data.End[0] : data.End[1]);
    }
  }, [loading]);

  // Handler
  const lastSummaryElement = React.useCallback((node) => {
    if (loading || !node || !hasMore) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prev) => prev + 1);
      }
    });

    observer.current.observe(node);
  });

  const handleFilters = (data) => {
    const { topic, location } = data;
    const params = {};

    if (topic?.length) params.topic = topic.join(",");
    if (location?.length) params.location = location.join(",");
    setSelectedFilter(data);
    handleSummaries([]);
    setFilters(params);
    setPage(1);
    setQuery("");
    setCloseDrawer(true);
  };

  const handleSearch = (data) => {
    setQuery(data);
    setSelectedFilter(initialFilters);
    setCloseDrawer(true);
  };

  const clearFilter = () => {
    localStorage.removeItem("topic");
    localStorage.removeItem("location");
    setQuery("");
    handleFilters({});
    setSelectedFilter(initialFilters);
    setCloseDrawer(true);
  };

  return (
    <main>
      <Header closeDrawer={closeDrawer} setCloseDrawer={setCloseDrawer}>
        {!closeDrawer && (
          <DrawerFilters
            handleFilters={handleFilters}
            selectedFilters={selectedFilters}
            handleSearch={handleSearch}
            query={query}
          />
        )}
      </Header>
      <div className="pt-[100px] md:pt-[8rem]"></div>
      <div className='lg:flex justify-center  items-center mx-4 md:mx-10 lg:mx-0'>
        <div className='  lg:w-[75%]  '>
          {summaries.length !== 0 && (
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 780: 2, 1300: 3 }}
            >
              <Masonry>
                {summaries.map((item, index) => (
                  <div
                    key={item.uuid}
                    className={clsx(
                      "my-2 md:m-3 lg:mx-2 lg:my-3",
                      item.article_category === "A" &&
                      "your-conditional-class-name"
                    )}
                  >
                    {index === summaries.length - 1 ? (
                      <NewsCardRef item={item} ref={lastSummaryElement} />
                    ) : (
                      <NewsCard item={item} />
                    )}
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}

          {loading && (
            <div className="mx-5">
              <Loaders type="feed" />
            </div>
          )}

          {!hasMore && !loading && (
            <ExploreButton onClick={clearFilter} end={end} />
          )}
        </div>
      </div>
    </main>
  );
};

export default Feed;
