import React, { forwardRef } from "react";
import NewsCardInner from "../NewsCardInner/NewsCardInner";

const NewsCardRef = forwardRef(
  (props, ref) => {
    return (
      <div className={props.classes} ref={ref}>
        <NewsCardInner item={props.item} />
      </div>
    );
  }
);

export default NewsCardRef;
