import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { initialFilters } from "../../pages/Feed/Feed";
import { getTopics, getLocations } from "../../services/News.Service";

import SearchBar from "../Search/Search";
import TopicChips from "../Chip/TopicChips";
import LocationChips from "../Chip/LocationChips";

const initialIsSaveable = () => {
  let isSaveable = false;
  const prev = localStorage.getItem("saveable");
  if (prev) return JSON.parse(prev);
  return isSaveable;
};

const DrawerFilters = ({
  handleFilters,
  selectedFilters,
  handleSearch,
  query,
}) => {
  const [filters, setFilters] = useState(selectedFilters);
  const [searchResult, setSearchResult] = useState(query);
  const [clicked, setClicked] = useState(false);
  const [isSaveable, setIsSaveable] = useState(initialIsSaveable());

  function handleBookmarkFilters() {
    localStorage.setItem("saveable", !isSaveable);
    setIsSaveable(!isSaveable);
  }

  useEffect(() => {
    localStorage.removeItem("topic");
    localStorage.removeItem("location");
    if (isSaveable) {
      filters.topic.length > 0 &&
        localStorage.setItem("topic", filters.topic.join(","));
      filters.location.length > 0 &&
        localStorage.setItem("location", filters.location.join(","));
    } else {
      localStorage.removeItem("topic");
      localStorage.removeItem("location");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveable, filters]);

  const locationQuery = useQuery({
    queryKey: ["location"],
    queryFn: () => getLocations().then((res) => [...res.data]),
  });

  const topicsQuery = useQuery({
    queryKey: ["topic"],
    queryFn: () => getTopics().then((res) => [...res.data]),
  });

  const handleFilterSelect = (item, type) => {
    setClicked(true);

    const updatedFilters = { ...filters };

    if (type === "l") {
      updatedFilters.location = filters.location.includes(item)
        ? filters.location.filter((i) => i !== item)
        : [...filters.location, item];
    } else {
      updatedFilters.topic = filters.topic.includes(item)
        ? filters.topic.filter((i) => i !== item)
        : [...filters.topic, item];
    }
    setFilters(updatedFilters);
  };

  useEffect(() => {
    if (
      searchResult !== null &&
      searchResult !== undefined &&
      searchResult !== selectedFilters.search &&
      clicked === true
    ) {
      handleSearch(searchResult);
    }
    // eslint-disable-next-line
  }, [searchResult]);

  return (
    <div className="">
      <h3 className="text-xl lg:text-2xl border-b border-whiteSmoke font-bold pb-1 md:pb-2 lg:pb-3 tracking-wide">
        Search & Filters
      </h3>
      <div className="my-2">
        {/* Search Bar */}
        <SearchBar
          setSearchResult={setSearchResult}
          setClicked={setClicked}
          searchResult={searchResult}
          classes="my-4 lg:my-5"
        />

        <div className="mb-5 ">
          {/* Topic Filters */}
          <TopicChips
            topicsQuery={topicsQuery}
            filters={filters}
            handleFilterSelect={handleFilterSelect}
          />

          {/* Loction Filters */}
          <LocationChips
            locationQuery={locationQuery}
            filters={filters}
            handleFilterSelect={handleFilterSelect}
          />

          {/* Bookmark */}
          <div className="flex items-center justify-end mt-6">
            <button
              onClick={handleBookmarkFilters}
              className="transition-all duration-[300ms] ease-in-out w-full hover:scale-90 bg-secondary text-sm md:text-md block text-white font-semibold px-2 md:px-8 py-2 md:py-3 rounded-full hover:text-black hover:bg-white"
            >
              Bookmark
            </button>
          </div>
          {/* Apply and clear all */}
          <div className="flex flex-wrap gap-4 mt-3 justify-end">
            {(selectedFilters?.location?.length ||
              selectedFilters?.topic?.length ||
              query) && (
              <button
                onClick={() => {
                  handleSearch("");
                  localStorage.removeItem("topic");
                  localStorage.removeItem("location");
                  handleFilters(initialFilters());
                }}
                className="transition-all duration-[300ms] ease-in-out hover:scale-90 bg-secondary text-sm md:text-md block text-white font-semibold px-4 md:px-8 py-2 md:py-3 rounded-full w-full hover:text-black hover:bg-white"
              >
                Clear All
              </button>
            )}
            <button
              onClick={() => handleFilters(filters)}
              className="transition-all duration-[300ms] ease-in-out hover:scale-90 bg-secondary text-sm md:text-md block text-white font-semibold px-4 md:px-8 py-2 md:py-3 w-full rounded-full hover:text-black hover:bg-white"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerFilters;
