import React from "react";
import { useQuery } from "@tanstack/react-query";
import Ticker from "react-ticker";
import { getTicker } from "../../services/News.Service";
import { useNavigate } from "react-router-dom";
import { createArticleUrl } from "../../utils/helpers";

function TickerForSection({ filterTopic }) {
  const navigate = useNavigate();
  const tickerQuery = useQuery({
    queryFn: async () => {
      const paramsData = {};
      paramsData.topic = filterTopic;
      const data = await getTicker(paramsData);
      return data.data;
    },
    queryKey: ["ticket", filterTopic],
  });

  return (
    <>
      {tickerQuery.isSuccess && (
        <div className="w-full  ">
          <Ticker mode="await" offset="100%" speed={4}>
            {({ index }) => (
              <div
                onClick={() =>
                  navigate(createArticleUrl(tickerQuery?.data[index]?.uuid))
                }
                className="text-blue-100 italic text-sm h-4  sm:h-4 hover:cursor-pointer hover:text-primary"
              >
                {tickerQuery?.data[index]?.title}
              </div>
            )}
          </Ticker>
        </div>
      )}
    </>
  );
}

export default TickerForSection;
