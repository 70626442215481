import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { createCopyUrl, getConciseDate } from "../../utils/helpers";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ReactComponent as LocationIcon } from "../../assests/icons/location-dot.svg";
import { ReactComponent as ShareIcon } from "../../assests/icons/share-nodes.svg";
import ClipboardJS from "clipboard";
import CustomSnackbar from "../Snackbar/Snackbar";
import clsx from "clsx";
import Image from "../Image/Image";

const NewsCardInnder = (props) => {
  const {
    item: {
      article_category,
      main_image,
      title,
      timestamp,
      response,
      topic,
      links,
      location,
      uuid,
      location_counts,
    },
  } = props;
  const navigate = useNavigate();
  const excerptLimit = 100;
  const [excerpt, setExcerpt] = useState("");
  const [expand, setExpand] = useState(false);
  // const currentLocation = useLocation();
  const [state, setState] = React.useState({
    open: false,
    message: "Copied to Clipboard",
  });
  const { open, message } = state;

  function handleNavigate() {
    navigate(`/article/${uuid}`);
    // if (currentLocation.pathname.split('/')[1] !== "article") {  }
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  function copyUuid(data) {
    const clipboard = new ClipboardJS(".copy-button", {
      text: () => data,
    });

    clipboard.on("success", function (e) {
      setState({ ...state, open: true });
      e.clearSelection();
    });

    clipboard.on("error", function (e) {
      setState({ ...state, open: true });
      setState({ ...state, message: "Coping url Failed ❌" });
      e.clearSelection();
    });
  }

  useEffect(() => {
    if (!expand) {
      const _excerpt =
        response?.length > excerptLimit
          ? response.slice(0, excerptLimit) + "..."
          : response;
      setExcerpt(_excerpt);
    } else {
      setExcerpt(response);
    }
  }, [expand, response]);

  return (
    <>
      {/* Image */ }
      <div
        className={ clsx(
          article_category === "B" ? "border-secondary " : "border-red-900",
          "leightBlack-bg  border-t-4  rounded-b-xl "
        ) }
      >
        { main_image?.main_image && (
          <div className="imageWrapper h-60  relative">
            <a
              href={ main_image?.link }
              target="_blank"
              title={ main_image?.main_image }
              rel="noreferrer"
            >
              <Image image={ main_image } title={ title } position={ "absolute" } height={ "100%" } width={ "100%" } effect={ " " } />
            </a>
          </div>
        ) }
        <div className="px-4 pb-4 rounded-b-xl shadow-inner darkgray-shadow">
          {/* Topic & Date */ }
          <div className="flex justify-between items-center py-5">
            <span className="text-xs text-whiteSmoke font-bold">{ topic }</span>
            <span className="text-xs text-whiteSmoke font-bold">
              { timestamp && getConciseDate(timestamp) }
            </span>
          </div>

          {/* Title */ }
          <div className="">
            <h2
              className="font-semibold mb-4 cursor-pointer hover:text-neutral-400"
              onClick={ handleNavigate }
            >
              { title }
            </h2>
            {/* location tags */ }
            { location.toLowerCase() !== "all" && location.length !== 0 ? (
              <div className="inline-flex rounded-full my-1 mr-2 px-2 gap-1 py-1 tracking-wide bg-secondary">
                <LocationIcon
                  width="12"
                  height="12"
                  fill="#ffffff"
                  className="my-auto mr-1"
                />
                <span className=" text-xs text-white ">{ location }</span>
              </div>
            ) : (
              <>
                { location_counts &&
                  location.length !== 0 &&
                  Object.entries(location_counts)?.map((i, index) => {
                    return (
                      <div
                        className="inline-flex items-center rounded-full my-1 mr-2 px-2 gap-1 py-1 tracking-wide bg-secondary"
                        key={ index }
                      >
                        <LocationIcon
                          width="12"
                          height="12"
                          fill="#ffffff"
                          className="my-auto mr-1"
                        />
                        <span className="text-xs text-white mr-2">{ i[0] }</span>
                        { i[1] && i[1] > 1 && (
                          <div className="w-5 h-5 bg-[#1b1b1b] rounded-full flex items-center justify-center text-xs">
                            { i[1] }
                          </div>
                        ) }
                      </div>
                    );
                  }) }
              </>
            ) }

            <p className="text-gray-300 mt-2">{ excerpt }</p>
          </div>
          {/* discription */ }
          { expand && links?.length !== 0 && (
            <div>
              <span className="inline-block text-lg my-4 font-bold text-white">
                Sources
              </span>
              <div className="flex flex-wrap gap-y-2">
                { links?.map((i, index) => {
                  return (
                    <a
                      key={ index }
                      href={ i }
                      title={ i }
                      target="_black"
                      className="flex text-whiteSmoke font-bold capitalize hover:text-white mr-4"
                    >
                      source { index + 1 }
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="20"
                        height="20"
                        fill="whiteSmoke"
                        className="ml-1"
                        viewBox="0 0 64 64"
                      >
                        <path d="M 40 10 C 38.896 10 38 10.896 38 12 C 38 13.104 38.896 14 40 14 L 47.171875 14 L 30.585938 30.585938 C 29.804938 31.366938 29.804938 32.633063 30.585938 33.414062 C 30.976938 33.805063 31.488 34 32 34 C 32.512 34 33.023063 33.805062 33.414062 33.414062 L 50 16.828125 L 50 24 C 50 25.104 50.896 26 52 26 C 53.104 26 54 25.104 54 24 L 54 12 C 54 10.896 53.104 10 52 10 L 40 10 z M 18 12 C 14.691 12 12 14.691 12 18 L 12 46 C 12 49.309 14.691 52 18 52 L 46 52 C 49.309 52 52 49.309 52 46 L 52 34 C 52 32.896 51.104 32 50 32 C 48.896 32 48 32.896 48 34 L 48 46 C 48 47.103 47.103 48 46 48 L 18 48 C 16.897 48 16 47.103 16 46 L 16 18 C 16 16.897 16.897 16 18 16 L 30 16 C 31.104 16 32 15.104 32 14 C 32 12.896 31.104 12 30 12 L 18 12 z"></path>
                      </svg>
                    </a>
                  );
                }) }
              </div>
            </div>
          ) }
          { response && (
            <span className="flex items-center mt-5 bg-pink-00">
              <button
                className="text-sm font-semibold text-gray-300 mx-auto hover:text-white transition-all ease-in-out duration-[400ms] hover:scale-105"
                onClick={ () => setExpand((prev) => !prev) }
              >
                { !expand ? "read more" : "read less" }
              </button>

              <button
                className="copy-button transition-all ease-in-out duration-[400ms] hover:scale-125"
                onClick={ () => {
                  copyUuid(createCopyUrl(uuid));
                } }
              >
                <ShareIcon
                  title="Copy Url"
                  width="16"
                  height="16"
                  className="my-auto fill-blue-500 "
                />
              </button>
            </span>
          ) }
        </div>
      </div>
      <div>
        <CustomSnackbar open={ open } message={ message } onClose={ handleClose } />
      </div>
    </>
  );
};

export default NewsCardInnder;
