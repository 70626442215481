import "./search.css";
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "../../assests/icons/search.svg";
import clsx from "clsx";

function SearchBar({
  setSearchResult,
  setClicked,
  searchResult,
  classes = "",
}) {
  const [searchText, setSearchText] = useState(searchResult);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleSearchClick = () => {
    setClicked(true);
    setIsButtonClicked(true);
    setSearchResult(searchText);

    setTimeout(() => {
      setIsButtonClicked(false);
    }, 300);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  return (
    <div className={ clsx("relative transition-all duration-[400ms] ease-in-out focus-within:scale-105", classes) }>
      <input
        type="text"
        placeholder="Search"
        className="h-8 md:h-10  text-base pl-4 pr-10 text-white bg-secondary rounded-full outline-none w-full"
        value={ searchText }
        onChange={ (e) => {
          setSearchText(e.target.value);
        } }
        onKeyPress={ handleKeyPress }
      />
      { searchText !== searchResult && (
        <button
          onClick={ handleSearchClick }
          className={ `absolute inset-y-0 right-0 flex rounded-full bg-red-500 items-center w-8 h-8 md:h-10 md:w-10 justify-center cursor-pointer  
            ${isButtonClicked ? "button-click-animation" : ""}` }
        >
          <SearchIcon width="16" height="16" className="" />
        </button>
      ) }
    </div>
  );
}

export default SearchBar;
