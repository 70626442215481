import React from 'react'
import Loaders from '../Loaders/Loaders';
import Chip from './Chip';


const TopicChips = ({topicsQuery,filters,handleFilterSelect}) => {
  return (
    <>
    <h4 className="text-lg md:text-xl mt-7 lg:text-xl font-bold mb-2">
            Topics
          </h4>
          <div className="flex flex-wrap pr-1">
            {topicsQuery.isLoading ? (
              <Loaders type="chip" />
            ) : (
              <>
                {topicsQuery.data?.map((i, index) => {
                  const isActive = filters?.topic?.includes(i);
                  return (
                    <Chip
                      text={i}
                      key={index}
                      isActive={isActive}
                      onClick={() => handleFilterSelect(i, "t")}
                    />
                  );
                })}
              </>
            )}
          </div>
    </>
  )
}

export default TopicChips