import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";

const Popup = ({
  showLocationModal,
  handleLocationModalClose,
  handleLocationSelect,
  handleLocationModalSave,
  locationQuery,
  selectedLocations,
}) => {
  return (
    <div>
      {!locationQuery.isSuccess ? null : (
        <>
          <Dialog
            open={showLocationModal}
            onClose={handleLocationModalClose}
            fullWidth
            maxWidth="md"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "0px",
                border: "1px solid #979A9A",
              },
            }}
          >
            <DialogTitle
              sx={{
                background: "black",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              Choose Locations
              <IconButton
                onClick={handleLocationModalClose}
                sx={{ color: "white" }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              sx={{
                background: "black",
                borderRadius: "0px !important",
                p: 2,
              }}
            >
              <Typography component="p" variant="body1" color="lightgray" mb={3} px={1}>
                Welcome to Generative News! To enhance your experience and
                deliver personalized news tailored to your preferences, please
                select your preferred locations below. These choices will be
                stored as cookies.
                <br />
                <br />
                By choosing your preferred locations, you ensure that the news
                you receive is relevant to your interests and surroundings. Once
                you've made your selections, click "Save" to apply your
                preferences.
              </Typography>
              {locationQuery?.data?.map((location, index) => (
                <Chip
                  label={location}
                  key={index}
                  onClick={() => handleLocationSelect(location)}
                  sx={{
                    margin: 0.75,
                    backgroundColor: selectedLocations.includes(location)
                      ? "grey"
                      : "black",
                    color: selectedLocations.includes(location)
                      ? "white"
                      : "white",
                    borderColor: "white",
                    borderWidth: 1,
                    borderStyle: "solid",
                    ":hover": { backgroundColor: "grey" },
                  }}
                />
              ))}
            </DialogContent>
            <DialogActions
              sx={{
                backgroundColor: "black",
                pb: 3,
                px: 3,
              }}
            >
              <button
                disabled={!selectedLocations?.length}
                onClick={handleLocationModalSave}
                className="transition-all duration-[300ms] ease-in-out hover:scale-90 bg-secondary text-sm md:text-md block text-white font-semibold px-4 md:px-8 py-2 md:py-3  rounded-full hover:text-black hover:bg-white"
              >
                Save
              </button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default Popup;
