import React from "react";
import Skeleton from "react-loading-skeleton";
import SkeletonWrapper from "../Skeleton/Skeleton";

const ChipLoader = ({ count }) => {
  return (
    <SkeletonWrapper>
      {[...Array(count).keys()].map((_, index) => {
        return (
          <div className={`w-44 mr-4`} key={index}>
            <Skeleton
              width="100%"
              className="my-[8px] w-full h-[32px] !rounded-[16px] px-[14px] py-4"
            />
          </div>
        );
      })}
    </SkeletonWrapper>
  );
};

export default ChipLoader;
