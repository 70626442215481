import React from "react";
import FeedLoader from "./Feed.Loader";
import ChipLoader from "./Chip.Loader";
import HomeLoader from "./Home.Loader";

import TimelineLoader from "./Timeline.Loader";

const Loaders = ({ type, count = 3 }) => {
  let Loader;

  switch (type) {
    case "feed":
      Loader = <FeedLoader />;
      break;
    case "home":
      Loader = <HomeLoader />;
      break;

    case "timeline":
      Loader = <TimelineLoader />;
      break;
    case "chip":
      Loader = <ChipLoader count={ count } />;
      break;
    default:
      Loader = <FeedLoader />;
  }

  return <>{ Loader }</>;
};

export default Loaders;
