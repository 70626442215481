import React, { useRef, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const AnimatedImage = ({ src, alt, overlay = false }) => {
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls1.start({ opacity: 1, x: "100%" });
            controls2.start({ opacity: 1, x: "100%" });
          }
        });
      },
      { threshold: 0.5 }
    );

    const currentContainerRef = containerRef.current;

    if (currentContainerRef) {
      observer.observe(currentContainerRef);
    }

    return () => {
      if (currentContainerRef) {
        observer.unobserve(currentContainerRef);
      }
    };
  });

  return (
    <div
      ref={containerRef}
      style={{ position: "relative", width: "100%", overflow: "hidden" }}
    >
      <motion.div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "black",
        }}
        initial={{ opacity: 0, x: 0 }}
        animate={controls1}
        transition={{ duration: 0.5 }}
      />
      <motion.div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "black",
        }}
        initial={{ opacity: 0, x: 0 }}
        animate={controls2}
        transition={{ duration: 0.5, delay: 0.2 }}
      />

      {overlay && (
        <div className="absolute w-full h-full top-0 bottom-0 bg-[hsl(40,1%,40%)] opacity-50"></div>
      )}
      <motion.img
        src={src}
        alt={alt}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default AnimatedImage;
