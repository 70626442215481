import { useEffect, useState } from "react";
import { getTimeLine } from "../../services/News.Service";
import { TimelineItem } from "../../components/Timeline/TimeLineItem";
import Loaders from "../../components/Loaders/Loaders";
import Header from "../../components/Header/Header";
import { AnimatePresence, motion } from "framer-motion";
import { toggleScrollLock } from "../../utils/helpers";
import { ReactComponent as GoBack } from "../../assests/icons/GoBack.svg";
import SmartArticle from "../Article/Article";

function TimeLine() {
  const [closeDrawer, setCloseDrawer] = useState(false);
  const [timelineData, setTimelineData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [article, setArticle] = useState();
  let i = 0;

  useEffect(() => {
    toggleScrollLock(true);
    const timeLineItems = () => {
      getTimeLine().then((res) => {
        setTimelineData(res.data);
        setIsLoading(false);
        window.scrollTo(0, 0);
      });
    };
    timeLineItems();
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 27 && article) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const handleClose = () => {
    toggleScrollLock(true);
    setArticle();
  };

  return (
    <>
      <Header closeDrawer={closeDrawer} setCloseDrawer={setCloseDrawer} />
      {isLoading && (
        <div className="mx-8 sm:mx-[6rem] md:mx-auto md:w-[47rem] h-fit pt-20">
          <Loaders type="timeline" />
        </div>
      )}
      {!isLoading && (
        <div className="relative pt-20">
          <div className="absolute grid mx-8 sm:mx-[6rem] md:mx-auto right-0 justify-end md:justify-center top-0 h-full w-full -z-10">
            <div className="border-l-[3px] h-full border-divider" />
          </div>
          <div className="grid mx-5 sm:mx-20 md:mx-auto relative h-fit overflow-y-auto overflow-x-hidden custom-scrollbar w-fit">
            {Object.entries(timelineData).map(([key, value]) => {
              return (
                <div key={key}>
                  <TimelineItem
                    data={timelineData[key]}
                    index={i++}
                    setArticle={setArticle}
                    tmpKey={key}
                  />
                </div>
              );
            })}
          </div>
          <>
            {article && (
              <div className="fixed !top-0 w-full h-full backdrop-blur-sm flex justify-center  z-30">
                <div
                  className=" !top-0  w-full h-full backdrop-blur-sm  cursor-pointer flex justify-center  z-10"
                  onClick={handleClose}
                />
                {/* Article Drawer */}
                <AnimatePresence mode="wait">
                  <motion.div
                    className=" absolute h-full !overflow-y-scroll bg-white dark:bg-black w-full  sm:w-[92%] md:w-[93%] lg:w-[85%]  right-0 top-0 pt-10 pb-32 mb:pb-20 lg:pb-23 px-2 sm:px-4 lg:pr-40 xl:pr-60 border-l dark:border-stone-700 z-30"
                    initial={{ x: "100%" }}
                    animate={{ x: "0" }}
                    exit={{ x: "100%" }}
                    transition={{ ease: "easeInOut", stiffness: 10 }}
                  >
                    <SmartArticle uuid={article} />
                  </motion.div>
                </AnimatePresence>
                {/* Go back  */}
                <span
                  onClick={handleClose}
                  className="absolute bg-white bg-opacity-30 lg:rounded-full transition-all ease-in-out lg:hover:scale-90 h-9 w-9 sm:h-10 sm:w-10  top-[57px] sm:top-24 left-0 lg:left-10  z-40 cursor-pointer"
                >
                  <GoBack className="h-9 w-9 sm:h-10 sm:w-10 fill-transparent stroke-white" />
                </span>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
}

export default TimeLine;
