/* eslint-disable react-hooks/exhaustive-deps */
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect, useMemo } from "react";
import { data } from "../../utils/constant";
import { toggleScrollLock } from "../../utils/helpers";
import { useLocation, Link } from "react-router-dom";
import NewsTicker from "../NewsTicker/NewsTicker";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const path01Variants = {
  open: { d: "M3.06061 2.99999L21.0606 21" },
  closed: { d: "M0 9.5L24 9.5" },
};

const path02Variants = {
  open: { d: "M3.00006 21.0607L21 3.06064" },
  moving: { d: "M0 14.5L24 14.5" },
  closed: { d: "M0 14.5L15 14.5" },
};

export default function Header({ closeDrawer, setCloseDrawer, children }) {
  const nav = useNavigate();
  const location = useLocation();
  const currentPage = "/" + location.pathname.split("/")[1];
  const [animation, setAnimation] = useState("closed");
  const [isBackdropBlurred, setIsBackdropBlurred] = useState(false);
  const [tickerState, setTickerState] = useState(false);

  const titleData = useMemo(() => {
    const rout = ["/", "/newsfeed", "/aboutus", "/article", "/timeline"];
    const route = rout.find((rout) => rout === currentPage);
    return new Map(Object.entries(data.pages)).get(route ? currentPage : "/");
  }, [currentPage]);

  const routes = [
    { path: "/", text: "Home" },
    { path: "/newsfeed", text: "News Feed" },
    { path: "/aboutus", text: "About Us" },
    { path: "/timeline", text: "Timelines" },
  ];

  const handleOpenClose = () => {
    setCloseDrawer(false);
    toggleScrollLock(animation === "open");
    setIsBackdropBlurred(!isBackdropBlurred);
    setAnimation(animation === "closed" ? "open" : "closed");
  };

  const onClick = () => {
    setAnimation("moving");
    handleOpenClose();
  };

  useEffect(() => {
    if (["/aboutus", "/timeline"].includes(currentPage)) setTickerState(false);
    else setTickerState(true);

    if (closeDrawer) {
      handleOpenClose();
    }
  }, [closeDrawer]);

  return (
    <div className=" fixed w-full bg-black bg-opacity-95 z-50">
      {/* Backdrop with blur effect */}
      {animation === "open" && isBackdropBlurred && (
        <div
          className="fixed !top-0 !left-0 w-full h-full backdrop-brightness-50 backdrop-blur-sm z-40"
          onClick={handleOpenClose}
        />
      )}
      <div className=" flex flex-wrap !top-0 !left-0 ">
        <header className="flex w-full  justify-between border-b border-neutral-600 z-50  h-fit">
          <div
            className="px-5 py-3 md:px-6 lg:px-7 cursor-pointer"
            onClick={() => nav("/")}
          >
            <h1 className="text-2xl md:text-3xl font-bold capitalize tracking-wide">
              {titleData?.title}
            </h1>
            <p className="text-sm hidden md:block md:text-md font-semibold text-red-600  tracking-wide">
              {titleData?.titleLine}
            </p>
          </div>
          <button onClick={onClick} className="px-5 py-2 md:px-6 lg:px-7">
            <svg width="26" height="24" viewBox="0 0 24 24">
              <motion.path
                stroke="#f5f5f5"
                strokeWidth={1.5}
                animate={animation}
                variants={path01Variants}
              />
              <motion.path
                stroke="#f5f5f5"
                strokeWidth={1.5}
                animate={animation}
                variants={path02Variants}
              />
            </svg>
          </button>
        </header>
        <div className=" relative  w-full z-30">
          <NewsTicker tickerState={tickerState} />
        </div>
      </div>

      {animation === "open" && (
        <AnimatePresence mode="wait">
          <motion.div
            className={clsx(
              "fixed h-full !overflow-auto custom-scrollbar w-72 md:w-96 lg:w-96 bg-black right-0  pb-32 mb:pb-20 lg:pb-23 z-50 border-r border-stone-900"
            )}
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "100%" }}
            transition={{ ease: "circOut", stiffness: 100 }}
          >
            <div className="mb-4 md:mb-3 lg:mb-2">
              <nav className="my-5">
                <ul>
                  {routes.map((route, index) => (
                    <li key={index}>
                      <Link
                        key={route.path}
                        to={route.path}
                        className={`flex w-full text-base md:text-lg lg:text-xl py-2 tracking-wide outline-none
                                      ${
                                        currentPage === route.path
                                          ? "border-l-4 pl-4 highlter-bg border-red-600 font-bold "
                                          : "pl-4 font-medium hover:font-semibold hover:bg-zinc-800"
                                      }
                            ${
                              currentPage === route.path
                                ? "pointer-events-none"
                                : ""
                            } 
                            `}
                      >
                        {route.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="mx-4">{children}</div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}
