import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonWrapper = (props) => {
  return (
    <SkeletonTheme baseColor="#404040" highlightColor="#303030">
      {props.children}
    </SkeletonTheme>
  );
};

export default SkeletonWrapper;
