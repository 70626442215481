import React from "react";
import { data } from "../../utils/constant";
import AnimatedImage from "../AnimateImage/AnimateImage";
// images
import nine from "../../assests/images/black.jpeg";
import two from "../../assests/images/two.jpeg";
import ten from "../../assests/images/ten.jpeg";

// css
import "./Inner.css";

const Inner = () => {
  return (
    <div className="my-24 lg:my-32">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-5 order-2 lg:order-1 px-3 py-12 md:py-7 lg:px-5 lg:py-8">
          <p className="text-lg xl:text-2xl">{data.paraOne}</p>
        </div>
        <div className="col-span-12 lg:col-span-7 order-1 lg:order-2">
          <AnimatedImage src={two} alt={data.pageTitle} />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-5 image-container">
          <AnimatedImage src={ten} alt={data.pageTitle} />
        </div>
        <div className="col-span-12 lg:col-span-7 px-3 py-12 md:py-7 lg:px-5 lg:py-8">
          <p className="text-lg xl:text-2xl">{data.paraTwo}</p>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-5 order-2 lg:order-1 px-3 py-12 md:py-7 lg:px-5 lg:py-8">
          <p className="text-lg xl:text-2xl">{data.paraThree}</p>
        </div>
        <div className="col-span-12 lg:col-span-7 order-1 lg:order-2 image-container">
          <AnimatedImage src={nine} alt={data.pageTitle} />
        </div>
      </div>
    </div>
  );
};

export default Inner;
