import { ReactComponent as TwitterIcon } from "../assests/icons/x-twitter.svg";
import { ReactComponent as LinkdinIcon } from "../assests/icons/linkedin-in.svg";

export const data = {
  pageTitle: "Generative News",
  heroTitle: ["The Future of Journalism", "Distilled to Facts."],
  paraOne:
    "In an age of information overload and biased narratives, finding factual, unopinionated news can be a challenge. Generative News redefines the way you consume daily happenings by aggregating from myriad sources sifting through the noise and extracting only the essentials to provide an objective view of facts.",
  paraTwo:
    "Empower your understanding by customizing your news streams. Choose topics, locales, and languages that matter to you. Experience a personalized news journey that adapts to your preferences, while always prioritizing transparency and clarity. Each highlight comes with a direct reference to its original source, ensuring accountability.",
  paraThree:
    "The future of news isn't in sheer volume, but in precision and authenticity. With Generative News, step into a world where headlines are free from sensationalism, articles stand on facts, and your understanding evolves from broad perspectives. Witness a revolution in news consumption, tailored just for you.",
  ctaTitle: "Begin Your Tailored News Journey Now",
  info: "we've embraced the future of information. Our cutting-edge AI delves into a vast universe of news feeds, ensuring no perspective is left behind. Sieving through layers of viewpoints to craft a snapshot that's as close to the unbiased truth as possible. This meticulous process guarantees that what reaches you is not just news, but a reflection of events free from emotions, bias, or distortion. Trust in our approach means trusting in a world where facts are paramount and unpolluted truth is a given.",
  footerTitle: "Get In Touch",
  footerPara:
    "Experience the future of news: precise, authentic, and tailored just for you.",
  link: "/newsfeed",
  work: {
    title: "How It Works",
    para: "At Generative News, we redefine how you consume global events.Trust in Generative News is trust in a future where facts reign supreme, and pure, untainted truth is delivered to your inbox. Here’s how it works:",
    points: [
      {
        title: "Global Reach with Precision",
        para: "Using state-of-the-art large language models, our system scans over 10,000 news sources from around the world, ensuring no narrative escapes our purview, regardless of language.",
      },
      {
        title: "Rigorous Cross-Referencing",
        para: "Using state-of-the-art large language models, our system scans over 10,000 news sources from around the world, ensuring no narrative escapes our purview, regardless of language.",
      },
      {
        title: "Eliminating Bias",
        para: "Our commitment goes beyond delivering news. We meticulously filter out opinions, emotions, and biases, ensuring that you receive a balanced and impartial perspective.",
      },

      {
        title: "Digestible Summaries",
        para: "With our sophisticated algorithms, we transform a world of information into a concise newsletter digest, tailored to your preferences. This means you can stay informed about any part of the world, in any language, on your schedule",
      },
    ],
  },
  about: {
    title: "Get In Touch",
    social: [
      {
        icon: TwitterIcon,
        link: "twitter.com/home",
        linkText: "Follow Us on Linked In",
        para: "Do you need help right now? Contact our customer service?",
      },
      {
        icon: LinkdinIcon,
        linkText: "Follow Us on Twitter",
        link: "linkedin.com/feed/",
        para: "Do you have a question? We can help you through our email channel?",
      },
      {
        icon: TwitterIcon,
        link: "twitter.com/home",
        linkText: "Follow Us on Linked In",
        para: "Do you need help right now? Contact our customer service?",
      },
      {
        icon: LinkdinIcon,
        linkText: "Follow Us on Twitter",
        link: "linkedin.com/feed/",
        para: "Do you have a question? We can help you through our email channel?",
      },
    ],
  },
  pages: {
    "/newsfeed": {
      title: "News Feed",
      titleLine: "The Future of Journalism Distilled to Facts.",
    },
    "/": {
      title: "Generative News",
      titleLine: "The Future of Journalism Distilled to Facts.",
    },
    "/aboutus": {
      title: "About Us",
      titleLine: "The Future of Journalism Distilled to Facts.",
    },
    "/article": {
      title: "Generative News",
      titleLine: "The Future of Journalism Distilled to Facts.",
    },
    "/timeline": {
      title: "News Timelines",
      titleLine: "The Future of Journalism Distilled to Facts.",
    },
  },
  End: [
    {
      endMessage: "No content found",
      endButton: "Explore more!",
    },
    {
      endMessage: "You've reached the end ",
      endButton: "Keep exploring!",
    },
  ],
  tickerTitle: "News Titles",
  footerContent: {
    cards: [
      {
        icon: TwitterIcon,
        title: "Simplicity",
        para: "Things being beautifully simple are at the heart of everthing we do",
      },
      {
        icon: TwitterIcon,
        title: "Simplicity",
        para: "Things being beautifully simple are at the heart of everthing we do",
      },
      {
        icon: TwitterIcon,
        title: "Simplicity",
        para: "Things being beautifully simple are at the heart of everthing we do",
      },
    ],
  },
};

export const topics = [
  "Politics",
  "Sport",
  "Entertainment",
  "Business",
  "Society",
  "Tech",
];
