
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { createArticleUrl, createFeedUrl } from "../../../utils/helpers";
import Image from "../../Image/Image";
import TickerForSection from "../../NewsTicker/TickerForSection";

export default function Politics({ section, pageSize }) {
   const nav = useNavigate();
   return (
      <>
         { section[0]?.title &&
            (<div>
               {/* Topic and Ticker for topic */ }
               { section[0]?.topic && (
                  <div className="flex gap-1 pb-8  md:gap-2 items-center" >
                     <button
                        className="text-white border-b-2  border-red-600 text-xs md:text-sm font-bold hover:text-neutral-400"
                        onClick={ () => nav(createFeedUrl(section[0].topic)) }
                     >
                        { section[0].topic }
                     </button>
                     <TickerForSection filterTopic={ section[0].topic } />
                  </div>
               ) }
               {/* Section Data */ }
               <div className="">
                  {/* main Grid */ }
                  <div className=" md:grid  md:grid-cols-7  gap-5 relative w-full">
                     {/* Main inner 1 */ }
                     <div className=" md:col-span-5   >bg-red-500">
                        {/* Inner Grid */ }
                        <div className=" sm:grid  sm:grid-cols-2 gap-10 relative w-full  ">
                           { section.slice(0, 2).map((section, index) => (
                              <a
                                 key={ index }
                                 className="hover:text-neutral-400 "
                                 title={ section.title }
                                 href={ createArticleUrl(section.uuid) }
                              >
                                 <div className="mb-10 md:mb-0">
                                    <div className="imageWrapper h-[200px]  ">
                                       <Image image={ section.main_image } title={ section.title } position="relative" />
                                    </div>
                                    <div className=" pt-3">
                                       <h1 className="text-base font-bold pb-2">{ section.title }</h1>
                                       <p className="text-xsl text-gray-400 pr-12 ">{ section.response.slice(0, 150) + "..." }</p>
                                    </div>
                                 </div>
                              </a>
                           )) }
                           {/* left Image  right title bottom para  */ }
                           { section.slice(2, 6).map((sec, index) => (
                              <a
                                 key={ index }
                                 className="hover:text-neutral-400 "
                                 title={ sec.title }
                                 href={ createArticleUrl(sec.uuid) }
                              >
                                 <div className=" col-span-1  pb-8 sm:pb-0"
                                    key={ index }>
                                    {/* Image and Title grid */ }
                                    <div className=" grid grid-cols-3 ">
                                       <div className="flex col-span-1   ">
                                          <Image image={ sec.main_image } title={ sec.title } position="relative" height={ "120px" } objectFit={ "contain" } />
                                       </div>
                                       <div className="col-span-2 pl-4  ">
                                          <h1 className="font-bold text-sml ">{ sec.title }</h1>
                                       </div>
                                    </div>
                                    {/* Para */ }
                                    <div className="flex">
                                       <p className="text-xsl pt-2  text-gray-400 pr-12">{ sec.response.slice(0, 150) + "..." }</p>
                                    </div>
                                 </div>
                              </a>
                           )) }
                        </div>
                     </div>
                     {/* Mian Inner 2 */ }
                     <div className=" col-span-2 h-full md:h-[44rem] overflow-auto custom-scrollbar  md:border-l  border-neutral-700  md:pt-0 sm:mt-10 sm:mx-2 md:m-0 md:pl-4  ">
                        { section.slice(7, 12).map((section, index) => (
                           <a
                              key={ index }
                              className=" hover:text-neutral-400 mb-10 b"
                              title={ section.title }
                              href={ createArticleUrl(section.uuid) }
                           >
                              <div className="  mb-4  md:px-0  border-b pb-5 border-neutral-800">
                                 <div className=" ">
                                    <h1 className="font-bold text-sm ">{ section.title }</h1>
                                    <p className="text-xsl  text-gray-400 pt-2">{ section.response.slice(0, 150) + "..." }</p>
                                 </div>
                              </div>
                           </a>
                        )) }
                     </div>

                  </div>
               </div>
            </div>
            )
         }
      </>
   );
}