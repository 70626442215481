import { useEffect, useState, useRef } from "react";
import { useSpring, animated } from "react-spring";
import {
  getConciseDate,
  getTimeFromStringDate,
  toggleScrollLock,
} from "../../utils/helpers";
import clsx from "clsx";
import Image from "../Image/Image";
import { useMediaQuery } from "@mui/material";

export function TimelineItem({ index, data, setArticle, tmpKey }) {
  const containerRef = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");
  const even = index % 2 === 0 ? true : false;
  const toLeft = even || isMobile;
  const intailState = toLeft ? -95 : 95;

  const { x } = useSpring({
    from: { x: intailState },
    to: { x: isAnimated ? 0 : intailState },
    // delay: 10,
    onRest: () => {
      if (!isAnimated) {
        setIsAnimated(true);
      }
    },
  });

  // Define an Intersection Observer callback
  const handleIntersect = (entries) => {
    if (entries[0].isIntersecting) {
      setIsAnimated(true);
    }
  };

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 0, // Trigger when at least 50% of the element is visible
    };
    const observer = new IntersectionObserver(handleIntersect, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <animated.div
      ref={ containerRef }
      id={ index }
      className={ clsx(
        " my-4 py-4 border-divider whitespace-break-spaces  md:max-w-[21rem] ",
        toLeft
          ? "border-r sm:pr-4"
          : "border-l text-left ml-[27rem] -mt-[20rem] sm:pl-4",
        !isMobile && even && " -mt-[5rem] ",
        isMobile && "mr-12",
        index === 0 && "mt-32 md:mt-40 "
      ) }
      style={ {
        transform: x.to((x) => `translatex(${x}%)`),
      } }
    >
      {/* title */ }
      <p
        className={ `font-bold text-white text-xl underline underline-offset-4 ${toLeft ? "text-right pr-3 sm:pr-1 " : " text-left pr-3 sm:pl-1"
          }` }
      >
        { getConciseDate(tmpKey) }
      </p>

      {/* line for ball */ }
      <div
        className={ clsx(
          "top-[20%] absolute px-[0.8rem] md:px-[1.4rem] border border-b border-divider",
          toLeft ? "-right-[1.8rem] md:-right-[3rem]" : " -left-[3rem]"
        ) }
      />
      {/* timeline ball */ }
      <div
        className={ clsx(
          "top-[20%] -mt-[5.2px] absolute p-[5px] border rounded-full bg-black  border-divider",
          toLeft ? "  -right-[2.4rem] md:-right-[3.4rem]" : " -left-[3.4rem]"
        ) }
      />
      <div className={ clsx(" absolute top-0   px-[6px] border-divider border-t",
        toLeft ? "  -right-[5px]" : " -left-[5px]"
      ) } />
      <div className={ clsx(" absolute bottom-0   px-[6px] border-divider border-t",
        toLeft ? "  -right-[5px]" : " -left-[5px]"
      ) } />
      {/* background for timline ball */ }
      <div
        className={ clsx(
          "top-[20%] -mt-[14px] absolute py-4 pr-10 bg-black -z-50",
          toLeft
            ? "-right-[4rem] md:-right-[5rem] rounded-r-full"
            : "rounded-l-full -left-[5rem]"
        ) }
      />
      { data.map((item, i) => {
        //timeline data
        const hasImage = item?.main_image?.main_image ? true : false;
        return (
          <div
            className="w-full md:min-w-[14rem] py-5 cursor-pointer text-neutral-200 hover:text-neutral-400 border-b-[0.5px] border-neutral-800 z-10"
            onClick={ () => {
              toggleScrollLock(false);
              setArticle(item.uuid);
            } }
            key={ i }
          >
            <div className="grid grid-cols-3 ">
              {/* image */ }
              <div
                className={ `w-full mt-[6px] pr-4 sm:pr-0  ${toLeft ? "order-2" : "order-1"
                  } ${hasImage ? "flex sm:col-span-1" : "hidden"}` }
              >
                <Image image={ item?.main_image } height="6rem" width="8rem" />
              </div>
              {/* description and title */ }
              <div
                className={ `  ${hasImage ? "col-span-2" : "col-span-3"}  ${toLeft ? "pr-4 order-1" : "pl-4 order-2"
                  }` }
              >
                <p className="text-divider text-sm sm:text-base mb-2">
                  { getTimeFromStringDate(item.timestamp) }
                </p>
                <p className="font-semibold  text-sm sm:text-md lg:text-base">
                  { item.title }
                </p>
              </div>
            </div>
          </div>
        );
      }) }
    </animated.div>
  );
}
