import "./NewsTicker.css";
import { getTicker } from "../../services/News.Service";
import { useQuery } from "@tanstack/react-query";
import { createArticleUrl } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

export default function NewsTicker({ filter, tickerState }) {
  const navigate = useNavigate();
  const tickerQuery = useQuery({
    queryFn: async () => {
      const paramsData = {};
      if (filter?.location) paramsData.location = filter.location;
      if (filter?.topic) paramsData.topic = filter.topic;
      const data = await getTicker(paramsData);
      return data.data;
    },
    queryKey: ["ticket"],
  });

  function handleNavToArticle(uuid) {
    if (uuid !== undefined && uuid !== null) {
      navigate(createArticleUrl(uuid));
    }
  }

  const animationDuration =
    tickerQuery?.data?.length * (tickerQuery?.data?.length > 30 ? 9 : 11); // Adjust the multiplier as needed

  return (
    <>
      { tickerState && tickerQuery.data?.length > 0 && (
        <div className=" absolute bg-black bg-opacity-70">
          <div className="news-container">
            <div className=" flex">
              <ul
                className="flex overflow-hidden"
                style={ {
                  animation: `scroll ${animationDuration}s infinite linear`,
                } }
              >
                { tickerQuery.data?.map((item, index) => (
                  <li
                    className="text-xs md:text-sm px-4 p-1 hover:cursor-pointer hover:text-primary"
                    onClick={ () => handleNavToArticle(item?.uuid) }
                    key={ index }
                  >
                    { item.title }
                  </li>
                )) }
              </ul>
            </div>
          </div>
        </div>
      ) }
    </>
  );
}
