import React from "react";
import clsx from "clsx";

const Chip = ({ text, isActive, onClick }) => {
  return (
    <div
      className={ clsx(
        " flex m-[4px] md:my-[5px]  transition-all duration-[400ms] ease-in-out  px-[10px] py-2 lg:text-sm   min-w-max h-[30px] cursor-pointer items-center rounded-[16px] border border-white bg-[transparent]  text-[11px] md:text-[12px] lg:text-[13px] font-semibold normal-case leading-loose text-neutral-200 ",
        isActive ? "!bg-white !text-black hover:scale-95 " : "hover:scale-105"
      ) }
      onClick={ onClick }
    >
      { text }
    </div>
  );
};

export default Chip;
