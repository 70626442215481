/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { topics } from "../../../utils/constant";
import { preFetchContext } from "../../../hooks/usePrefetch";
import { createArticleUrl } from "../../../utils/helpers";
import Image from "../../Image/Image";

import { useNavigate } from "react-router-dom";

const Random = () => {
  const nav = useNavigate();

  const { summaries } = useContext(preFetchContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    const result = topics?.flatMap((topic) => {
      const topicObjects = summaries?.filter((item) => item.topic === topic);
      return topicObjects?.slice(0, 3);
    });

    const _data = result.filter((i) => i !== undefined || i !== null);
    Array.isArray(_data) && _data.length && setData(_data);
  }, [summaries]);

  return (
    <div className="w-full mx-auto lg:w-[75%] mb-6 md:mb-10 px-5 md:px-14 lg:px-0">
      <div className="flex gap-1 mb-6 md:mb-10 md:gap-3 items-center">
        <span className="text-white border-b-2 mb-1 border-red-600 text-xs md:text-sm font-bold">
          More News
        </span>
      </div>
      <div className="flex flex-wrap justify-between gap-x-4 gap-y-8">
        {data &&
          data?.map((sec, index) => (
            <a
              key={index}
              className="w-[45%] md:w-1/4 xl:w-1/5 cursor-pointer"
              title={sec?.title}
              onClick={() => nav(createArticleUrl(sec?.uuid))}
            >
              <div>
                <div className="imageWrapper">
                  <Image
                    image={sec?.main_image}
                    title={sec?.title}
                    height={"10rem"}
                    width="100%"
                  />
                </div>
                <div className="flex">
                  <span className="font-semibold md:text-sm hover:text-neutral-300 pt-3">
                    {sec?.title}
                  </span>
                </div>
              </div>
            </a>
          ))}
      </div>
    </div>
  );
};

export default Random;
