import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import GN from "../../assests/images/GN.jpeg";


export default function Image({ image, title, width, height, maxHeight, position, maxWidth, radius, objectFit, effect }) {

  return (
    <>
      <LazyLoadImage
        src={ image?.main_image }
        width="100%"
        style={ {
          justifyContent: "center",
          objectFit: "cover",
          position: "" || position,
          width: width || "100%",
          height: height || "100%",
          backgroundImage: `url(${GN})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderRadius: "" || radius,
        } }
      />
    </>
  );
}
